<script>
import VueSlickCarousel from 'vue-slick-carousel'
import { partnersComputed } from '@state/helpers'

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        centerPadding: '150px',
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    }
  },
  computed: {
    ...partnersComputed,
  },
}
</script>

<template>
  <section class="block block--partner padder-50">
    <div class="container">
      <!-- <h3 class="text-color-green font-weight-bolder text-uppercase title-section text-center">Nos partenaires</h3> -->
      <h2 class="font-weight-bolder title-section-desc text-center mb-5">
        Ils nous font confiance
      </h2>
      <div class="my-4">
        <VueSlickCarousel v-bind="settings">
          <div
            v-for="partner in partners"
            :key="partner.name"
            class="item-carousel px-4"
          >
            <a :href="partner.website" target="_blank">
              <img
                :class="$style.bgIcon"
                class="item-carousel__img"
                :src="require(`@assets/images/${partner.logoWhite}`)"
                :alt="`Logo ${partner.name}`"
              />
            </a>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<style lang="scss" module>
@import '@design';
.arrows {
  font-size: $size-font-bigger;
}
</style>
